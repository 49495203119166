







































import Vue from 'vue'
import { NewPasswordApiFunc } from '@/api'
import BaseInputForm from '@/components/BaseInputForm.vue'
import BaseSubmitButton from '@/components/BaseSubmitButton.vue'
import BaseCancelButton from '@/components/BaseCancelButton.vue'

export type DataType = {
  mail?: string;
  showSuccessMessage: boolean;
}

export default Vue.extend({
  name: 'PasswordForgotScreen',

  components: {
    BaseInputForm,
    BaseSubmitButton,
    BaseCancelButton
  },

  data(): DataType {
    return {
      mail: undefined,
      showSuccessMessage: false
    }
  },

  computed: {
    canSubmit(): boolean {
      return !!this.mail
    }
  },

  methods: {
    async sendPasswordForgot(): Promise<void> {
      if (!this.canSubmit) {
        return
      }
      this.$setLoading(true)

      const { err } = await NewPasswordApiFunc().forgotPassword(this.mail)
      if (err) {
        this.$setLoading(false)
        this.$handleApiError(err, this.sendPasswordForgot)
        return
      }
      this.showSuccessMessage = true
      this.$setLoading(false)
      this.$setChecked(true)
    },

    afterLeave(): void {
      this.$setChecked(false)
    }
  }
})
