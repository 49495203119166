








import Vue from 'vue'

export default Vue.extend({
  name: 'BaseCancelButton',

  methods: {
    emitClick(): void {
      if (this.$globalData.loading) return
      this.$emit('click')
    }
  },
})
